.testimonialdata .slick-dots li.slick-active {
    background: #0050d5;
}
.testimonialdata .slick-dots li button:before, .testimonialdata .slick-dots li.slick-active button:before {
    color: transparent;
}
.testimonialdata .slick-dots li {
    background: #0050d5a8;
    border-radius: 100%;
}
*:focus-visible {outline: none;}
/* .testimonialdata .slick-slider .slick-list {
    overflow: visible;
} */
/* .testimonialdata {
    overflow: hidden;
} */
/* .testimonialdata .slick-track {height: 100%;} */

/* .devops-slider.slick-initialized .slick-slide {
    width: 1000px;margin: 0 auto;max-width: 1000px;} */

    .testimonialdata .devops-slider .slick-dots li {border-radius:0px;height: 10px;width: 10px;}
    .testimonialdata .devops-slider .slick-dots li.slick-active {width: 50px;}
    .devops-slider .slick-dots {bottom: -40px;}
    .testimonialtest {width: calc(100% - 350px)}

    @media screen and (max-width:992px) {
        .testimonialtest {width: 100%;}
        .testimonialdata .slick-initialized .slick-slide {
            padding: 0px 0px;
        }
    }